// @ts-check
/* globals jQuery */

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
	var $window = $(window);
	var isMobile = false;
	var gridBreakpoint = 768;
	var resizeTimer;
	var init = true;

	/**
	 * Add a back to top button which will display after the user scrolls 500 pixels
	 */
	var initBackTop = function() {
		$('.layout').append(
			'<a href="#" id="return-to-top" title="Back to Top" class="back-top hidden-top"><i class="fa fa-chevron-up"></i></a>'
		);

		var $backTop = $('#return-to-top');

		$window.on('scroll', function() {
			if ($(this).scrollTop() >= 500) {
				$backTop.removeClass('hidden-top');
			} else {
				$backTop.addClass('hidden-top');
			}
		});
		$backTop.on('click', function(e) {
			e.preventDefault();
			$('body,html').animate({ scrollTop: 0 }, 500);
		});
	};

	/**
	 * Animation for the mobile menu hamburger toggle
	 */
	var initAnimatedHamburger = function() {
		$('.navbar-toggle').on('click', function() {
			$(this).toggleClass('active');
		});
	};

	/**
	 * Search in the nav bar will have expand / collapse behavior
	 */
	var initHeaderSearch = function() {
		var $headerSearchForm = $('.header-search-form');
		$headerSearchForm.find('.btn').on('click', function(e) {
			if (!isMobile) {
				// If the search form is not currently active then we need to show it
				// Toggle the search form and skip the default button submit behavior
				if (!$headerSearchForm.hasClass('active')) {
					e.preventDefault();
					$headerSearchForm
						.addClass('active')
						.find('.form-control')
						.focus();
				} else {
					if (!$headerSearchForm.find('.form-control').val()) {
						e.preventDefault();
						$headerSearchForm
							.removeClass('active')
							.find('.form-control')
							.blur();
					}
				}
			}
		});
	};

	/**
	 * Handle browser resize events after a set delay period
	 */
	var resizeHandler = function() {
		clearTimeout(resizeTimer);
		var debounceMs = 250;
		if (init) {
			debounceMs = 0;
			init = false;
		}

		resizeTimer = setTimeout(function() {
			if ($(window).width() < gridBreakpoint) {
				isMobile = true;
			} else {
				isMobile = false;
			}
			if ($('body').hasClass('home')) {
				adjustCarouselCaptionSize('.carousel-caption-heading');
			}
		}, debounceMs);
	};

	/**
	 * Run through the carousel caption text to style each line
	 *
	 * @param {string} selector - selector for the element with the caption to style
	 */
	var styleCarouselCaptionLines = function(selector) {
		var $el = $(selector);

		// Loop through each of the captions
		$el.each(function() {
			var $this = $(this);

			// Wrap each word in a span
			var regex = /\b(?!span\b)\w+\b/g;
			$this.html(function(i, val) {
				return val.replace(regex, '<span class="word">$&</span>');
			});

			// Any span that is not a word span should be styled in the subheading style
			$this.find('span').not('.word').addClass('caption-subheader');

			$this.find('.word').each(function(i) {
				$(this).addClass('word' + i);
			});

			$this.find('.word0').wrap('<span class="caption-mainheader"/>');
			$this
				.find('.caption-subheader')
				.nextAll()
				.wrapAll('<span class="caption-mainheader"/>');
		});

		adjustCarouselCaptionSize(selector);
	};

	/**
	 * Dynamically adjust the swoop svg image so it is the full width of the users browser window
	 */
	var adjustSwoops = function() {
		var $swoopContainer = $('.swoop-container');
		if ($swoopContainer.length) {
			var $swoop = $swoopContainer.find('#gswoop');
			var viewbox = '0 0 ' + $window.width() + ' 100';
			$swoop.attr({
				viewBox: viewbox,
				height: '100%',
				width: '100%'
			});
			$swoopContainer.removeClass('swoop-container-loading');
		}
	};

	/**
	 * Adjust the different headings in the carousel caption to be as large as possible
	 * while remaining in their respective containers
	 *
	 * @param {string} selector
	 */
	var adjustCarouselCaptionSize = function(selector) {
		var $el = $(selector);
		$el.each(function() {
			var captionWidth = $(this).width();
			$(this).find('> span').each(function(i) {
				var $this = $(this);
				$this.css('width', captionWidth);
				if (i === 0) {
					$this.fitText(0.8);
				} else {
					$this.fitText();
				}
			});
		});
	};

	/**
	 * Add a scroll down button to the bottom of the first section of each page
	 * so that the user has a visual indicator to scroll down for longer pages
	 */
	var initScrollDownButton = function() {
		var $sections = $('.content section.page-section');
		if ($sections.length > 1) {
			var $firstSection = $sections.first();
			$firstSection.append(
				$(
					'<span class="scroll-down anchor-link"><span class="scroll-down-icon fa fa-angle-double-down"></span></a>'
				)
			);
		}
	};

	/**
	 * Initialize the homepage carousel
	 */
	var initHomepageCarousel = function() {
		styleCarouselCaptionLines('.carousel-caption-heading');

		$('#homepage-carousel').slick({
			accessibility: true,
			adaptiveHeight: false,
			autoplay: true,
			autoplaySpeed: 8000,
			arrows: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			fade: false,
			speed: 500,
			lazyLoad: 'progressive'
		});
	};

	/**
	 * Adjust caption sizes on homepage callouts so thay are equal heights
	 */
	var adjustCalloutAlignments = function() {
		var $calloutsWrapper = $('.home-callouts-wrapper');
		$calloutsWrapper.find('.callout-description').matchHeight();
	};

	/**
	 * Set up the common and page specific functions
	 */
	var GrantSolutions = {
		common: {
			/**
			 * JavaScript to be fired on all pages
			 */
			init: function() {
				resizeHandler();

				initBackTop();

				initAnimatedHamburger();

				initHeaderSearch();

				adjustSwoops();

				initScrollDownButton();
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
				$window.on('resize orientationchange', function() {
					resizeHandler();
				});
			}
		},
		// Home page
		home: {
			/**
			 * JavaScript to be fired when the home page is initialized
			 */
			init: function() {
				initHomepageCarousel();

				adjustCalloutAlignments();
			},
			/**
			 * Javascript to be fired when the home page has loaded
			 */
			finalize: function() {}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = GrantSolutions;
			funcname = funcname === undefined ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(
				document.body.className.replace(/-/g, '_').split(/\s+/),
				function(i, classnm) {
					UTIL.fire(classnm);
					UTIL.fire(classnm, 'finalize');
				}
			);

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
